<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nuevo cupón</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Coupon type?-->
        <div>
          <h6>Tipo de cupón</h6>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="type" v-model="coupon.type" vs-value="regular">Regular</vs-radio>
            </li>
            <li>
              <vs-radio vs-name="type" v-model="coupon.type" vs-value="delivery">Delivery gratis</vs-radio>
            </li>
            <li>
              <vs-radio vs-name="type" v-model="coupon.type" vs-value="father">Categoría padre</vs-radio>
            </li>
            <li>
              <vs-radio vs-name="type" v-model="coupon.type" vs-value="product">Por producto</vs-radio>
            </li>
          </ul>
        </div>
        <!--End-->
        <!-- Code -->
        <vs-input @blur="coupon.code= trimString(coupon.code)" label="Código" v-model="coupon.code"
                  class="mt-5 w-full" name="code"
                  v-validate="'required|min:1|max:30'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('code')">{{ errors.first('code') }}</span>

        <!-- Percentage -->
        <vs-input v-if="coupon.type!=='delivery'" label="Porcentaje" v-model.number="coupon.percentage"
                  class="mt-5 w-full" name="percentage"
                  v-validate="'required|numeric|min_value:1'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('percentage')">{{ errors.first('percentage') }}</span>

        <!-- Start -->
        <div class="mt-5">
          <label class="vs-select--label" for="startDate">Fecha de inicio</label>
          <datepicker label="Inicio" v-model="coupon.startDate" id="startDate"
                      v-validate="'required'" name="startDate"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('startDate')">{{ errors.first('startDate') }}</span>
        </div>

        <div class="mt-5">
          <label class="vs-select--label" for="endDate">Fecha de fin</label>
          <datepicker label="Inicio" v-model="coupon.endDate" id="endDate"
                      v-validate="'required'" name="endDate"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('endDate')">{{ errors.first('endDate') }}</span>
        </div>

        <!--Quantity-->
        <vs-input-number class="mt-5 mb-5" label="Cantidad:" v-model.number="coupon.quantity"
                         name="quantity" v-validate="'required|min_value:0'" min="0"/>
        <span class="text-danger text-sm"
              v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
        <!--End-->

        <!---Delivery-->
        <template v-if="coupon.type==='delivery'">
          <!--Min-->
          <vs-input type="number"
                    label="Pedido mínimo"
                    v-model.number="coupon.min"
                    class="mt-5 w-full" name="min"/>
          <span class="text-danger text-sm"
                v-show="errors.has('min')">{{ errors.first('min') }}</span>
          <!--End--->

          <!-- Departments -->
          <div class="mt-5">
            <label class="text-sm opacity-75">Departamento*</label>
            <v-select placeholder="Selecciona"
                      @input="coupon.province = {}"
                      label="nomDepartamento"
                      :options="departments" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="coupon.department" class="mb-4 md:mb-0" name="department"/>
          </div>
          <!--End--->

          <!-- Provinces -->
          <div class="mt-5">
            <label class="text-sm opacity-75">Provincia*</label>
            <v-select placeholder="Selecciona"
                      @input="coupon.delivery = {}"
                      label="nomProvincia"
                      :options="filteredProvince" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="coupon.province" class="mb-4 md:mb-0" name="province"/>
          </div>
          <!--End--->

          <!-- Districts -->
          <div class="mt-5">
            <label class="text-sm opacity-75">Distritos*</label>
            <v-select placeholder="Selecciona"
                      label="nomDistrito"
                      multiple
                      :options="filteredDistrict"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="coupon.district" class="mb-4 md:mb-0" name="district"/>
          </div>
          <!--End--->
        </template>
        <!--End-->

        <!--Father-->
        <template v-else-if="coupon.type==='father'">
          <!--Exclude categories-->
          <div class="mt-5">
            <label class="vs-select--label mt-5" for="categories">Excluir categorías</label>
            <v-select :clearable="true"
                      name="categories"
                      id="categories" multiple
                      label="name"
                      :options="categories"
                      v-model="coupon.categories"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
          </div>
          <!--End-->

          <!--Excluded products-->
          <div class="mt-5">
            <label class="vs-select--label mt-5" for="excludedProducts">Excluir productos</label>
            <v-select :clearable="true"
                      name="excludedProducts"
                      id="excludedProducts"
                      multiple
                      label="label"
                      :options="products"
                      v-model="coupon.excludedProducts"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
          </div>
          <!--End-->
        </template>
        <!--End-->

        <!--Product-->
        <template v-else-if="coupon.type ==='product'">
          <!--Included products-->
          <div class="mt-5">
            <label class="vs-select--label mt-5" for="includedProducts">Productos</label>
            <v-select multiple :clearable="true" name="includedProducts" id="includedProducts" label="label"
                      :options="products"
                      v-model="coupon.product"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
          </div>
        </template>
        <!--End-->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

export default {
  name: 'NewCoupon',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    provinces: {
      type: Array,
      required: true
    },
    districts: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      coupon: {
        department: {},
        province: {},
        district: []
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.coupon = {
          type: 'regular',
          quantity: 0,
          department: {},
          province: {},
          district: []
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    filteredProvince () {
      if (this.coupon.department) {
        return this.provinces.filter((province) => {
          return province.codDepartamento === this.coupon.department.codDepartamento
        }).sort((a, b) => {
          a.nomProvincia.localeCompare(b.nomProvincia)
        })
      }
      else {
        return []
      }
    },
    filteredDistrict () {
      if (this.coupon.province) {
        return this.districts.filter((district) => {
          return district.codProvincia === this.coupon.province.codProvincia
        }).sort((a, b) => {
          a.nomDistrito.localeCompare(b.nomDistrito)
        })
      }
      else {
        return []
      }
    }
  },
  methods: {
    /**
     * Save data in firestore
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          const obj = {
            ...this.coupon,
            state: true,
            createdAt: FieldValue.serverTimestamp(),
            uid: auth.currentUser.uid,
            used: 0
          }
          obj.code = obj.code.toUpperCase()
          // Save in firestore
          const docRef = await db.collection('coupons').add({
            ...obj,
          })
          obj.id = docRef.id
          this.$emit('add', obj)
          this.$emit('closeSidebar')
          this.$vs.notify({
            color: 'success',
            title: 'Cupón',
            text: 'Cupón creado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 800px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
